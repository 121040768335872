@import "../site/variables-v3";
@import "../site/common-v3";
@import "../site/utilities/icons-v3";
@import "../site/new-homepage/header";
@import "../site/footer-v3";
@import "../site/utilities/rem-calc";

.l-content {
  margin-top: 80px;
}

.navigation-link {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.navigation-title {
  margin-left: 20px;
}

@media (min-width: 769px) {
  .l-content {
    &_auth {
      display: flex;
      // height: 100vh;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: stretch;
      overflow: hidden;
    }
  }
  .l-spacing-col {
    display: none;
    order: 0;
    flex: 1 0 auto;
    align-self: auto;
  }
  .l-content-col {
    order: 0;
    flex: 0 0 30%;
    align-self: auto;
    padding: rem(100px 40px 170px 40px);
  }
  .l-photo-col {
    display: block;
    order: 0;
    flex: 0 0 50%;
    align-self: auto;
    background-color: #F3F5F7;
    background-position: center center;
    background-size: cover;
    padding: 4.5rem 6.375rem 10.625rem 4.25rem;

    &__inner {
      width: 55%;
      padding: 1.5rem;
      background-color: $color-white;
      box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.04);
      border-radius: 12.1913px;
      margin-bottom: 2rem;

      img {
        max-width: 100%;

      }
      h4{
        color: $color-black-new;
        font-weight: 600;
        font-size: rem(24px);
      }
      p{
        font-size: rem(16px);
        font-weight: 600;
      }

    }

    &__img {
      margin-bottom: 1.5rem;
    }

    &__feature {
      width: 60%;
      padding: 1.5rem;
      text-align: center;
      h4{
        color: $color-black-new;
        font-weight: 600;
        font-size: rem(24px);
      }
      p{
        color: $color-black-new;
        font-weight: 600;
        font-size: rem(16px);
        margin-bottom: 1rem;
      }
    }
  }
}

@media (min-width: 1230px) {
  .l-spacing-col {
    display: block;
  }

}

.l-content-col {
  padding: rem(40px 20px 20px 20px);
  width: 100%;
  margin: 0 auto;
  @media (min-width: 1230px) {
    width: 100%;
    padding: 4.5rem 3rem 14.625rem 12.25rem;
    flex: 0 0 60%;
  }

  &__inner {
    width: 100%;
    @media (min-width: 1230px) {
      width: 50%;
    }
  }

  .header__logo {
    width: 140px;
  }
}


.l-content_auth {
  * {
    font-family: $font-open-sans;
  }

  .h2 {
    color: $color-black-new;
  }

  .button {
    &.disabled {
      opacity: .2;
    }
  }

  .modal-dialog {
    &-centered {
      align-items: flex-end;
    }
  }

  a {
    color: $color-blue-new;
    font-weight: 600;
  }
}

.form-login {
  .help-block {
    color: #c13737;
  }

  input,
  .form-control,
  textarea {
    border: 1px solid $color-blue-new;
    padding: 1.5rem 1rem;
    border-radius: 10px;
  }

  select {
    padding: .5rem 1rem !important;
    height: 50px;
  }

  label {
    color: $color-blue-new;
    font-weight: 600;
    font-size: rem(12px);
  }

  &_forgot-pass {
    text-align: left;
    margin-bottom: rem(40px);

    a {
      color: $color-blue-new;
      text-decoration: underline;
    }
  }

  &_buttons {
    display: flex;
    flex-direction: column;

    .or {
      margin: 10px 0;
      position: relative;
      text-align: center;
      height: 30px;

      span {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        background: #fff;
        padding: 0 15px;
        font-weight: 600;
        color: #686F77;
      }

      &:before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #cfcfcf;
        top: 50%;
        position: absolute;
        left: 0;
      }
    }

    .btn {
      flex: 0 0 auto;
      align-self: auto;
    }

    .button {
      width: auto;
      min-width: auto;
      border-radius: 10px;
      padding: 10px 24px;
      font-weight: 600;

      &.facebook, &.google {
        position: relative;
        background-color: #fff;
        border: solid 1px #cfcfcf;

        .button__text {
          color: #000;
        }
      }

      &.google {
        margin-top: 12px;

        img {
          width: 24px;
        }
      }
    }

    .button_blue {
      background-color: $color-blue-new;
    }
  }


}

.register-page {

  &__inner {
    padding: 1rem 4rem 1rem 0;
    @media (max-width: $mobile-break) {
      padding: 1rem 0;
    }
  }

  .l-content-col__inner {
    width: 100%;

    .form-login {
      padding: 1rem 4rem 1rem 0;
      @media (max-width: $mobile-break) {
        padding: 1rem 0;
      }
    }


    .form-group {
      margin-top: 1.5rem;
    }

    .mt-input-radio__label {
      font-size: 14px;
      color:$color-black-new;
    }

    .mt-input-radio_color {
      width: 21px;
      height: 21px;
      border: 1px solid #babdbe;
      &:after{
        top: 4px;
        left: 4px;
        width: 11px;
        height: 11px;
      }
    }
  }

  .l-photo-col__inner {
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    width: 80%;
  }

  .user_type_options {
    @media (max-width: $desktop-s-break) {
      flex-direction: column;
    }
  }
}

.register-success{
  .l-content-col__inner{
    .heading-title{
      font-size: rem(36px);
      line-height: rem(49px);
    }
    .sub-title{
      font-size: rem(24px);
      line-height: rem(32px);
      font-weight: 600;
      color: $color-black-new;
    }
  }
}

.login-page-title{
  white-space:nowrap;
  @media (max-width: $mobile-break) {
    white-space: normal;
  }
}

.login__flash-message {
  background: #10B981;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 24px;
}

.button__text {
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #cfcfcf;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

#growls-tr {
  z-index: 1000000;
}

// Fix bootstrap z-index in login page
.modal {
  z-index: 99999999 !important;
}
.modal-backdrop {
  z-index: 104000 !important;
}

#auth-page {
  .warning-box {
    font-size: 18px;
    background: #FFF9E7;
    border-radius: 10px;
    width: auto;
    border-left: solid 9px #FFC932;
    padding: 18px 0 18px 24px;
    font-weight: 400;
  }

  .section-title {
    font-weight: 900;
    font-size: 22px;
    color: #435160;
    margin-top: 50px;
    font-family: 'Roboto', sans-serif;
  }

  .new_button {
    background: #2979FF;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    font-family: 'Roboto';
    height: 50px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    white-space: nowrap;
    cursor: pointer;

    &.default {
      background-color: #fff;
      border: 1px solid #cfcfcf;
      color: #000;
    }

    .prefix-icon {
      margin-right: 8px;
    }
  }
}

.login-existing-page {
  .or {
    span {
      font-size: 22px;
      font-weight: 900;
      color: #435160;
      font-family: 'Roboto', sans-serif;
    }
  }

  .email-box {
    display: flex;

    input {
      margin-right: 18px;
      margin-bottom: 0;
      max-width: 300px;
    }

    @media (max-width: $mobile-break) {
      flex-direction: column;

      input {
        max-width: 100%;
        margin: 0 0 8px 0;
      }

      button {
        width: 100%;
      }
    }
  }

  .phone-box {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;

    .phone {
      width: 300px;
      margin-right: 18px;
    }

    @media (max-width: $mobile-break) {
      flex-direction: column;

      .phone {
        width: 100%;
        margin: 0 0 8px 0;
      }

      button {
        width: 100%;
      }
    }
  }

  ._3party-login-buttons {
    display: flex;
    flex-direction: column;

    .new_button {
      max-width: 300px;

      &:first-child {
        margin-bottom: 12px;
      }
    }

    @media (max-width: $mobile-break) {
      .new_button {
        max-width: 100%;
      }
    }
  }
}

span.select2-container--default span.select2-selection--single{
  padding: 1.5rem 1rem;
  border-radius: 10px;
}
span.select2-container--default span.select2-selection--single, span.select2-dropdown{
  border: 1px solid #2979FF !important;
}
input.select2-search__field{
  outline: none;
}
span.select2-dropdown{
  border-top: none !important;
}
span.select2-selection__arrow{
  top: 12px !important;
}
span.select2-selection__rendered{
  margin-top: -14px;
  margin-right: 8px;
  font-size: 15px;
}

.l-photo-col {
    @media (max-width: $mobile-break) {
      display: none;
    }
}

.autocomplete-suggestions {
  background: rgb(255, 255, 255);
  border: 1px solid #2979FF;
  border-radius: 5px;
  overflow: scroll;
  z-index: 214748000 !important;

  .autocomplete-suggestion {
    cursor: pointer;
    padding: 7px 17px;

    &:hover {
      background-color: rgba(175, 198, 239, 0.38);
    }
  }
}
.city-input {
  position: relative;

  .icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }

  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }

  @keyframes around {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
  }

  .loader::after, .loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }
}